<template>
  <div class="sendmessage-modal-wrapper modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title has-text-centered">
        Send Message to Participants
      </p>
      <button
        class="delete"
        aria-label="close"
        @click="$parent.close()"
      ></button>
    </header>
    <section class="modal-card-body pt-2 pb-5">
      <b-notification
        :active="showError"
        @close="$store.commit('clearError')"
        type="is-danger"
        >{{ showError ? error.message : "" }}</b-notification
      >
      <div class="px-3">
        <p class="has-text-centered is-italic">
          <b>{{ activeGames.length }}</b> active game{{
            activeGames.length > 1 ? "s" : ""
          }}
          selected{{
            activeGames.length === 1 ? ' : "' + activeGames[0].name + '"' : ""
          }}
        </p>
        <p>
          <b>Which roles would you like to contact ?</b>
        </p>
        <b-field class="mt-4" grouped>
          <div class="block">
            <b-checkbox
              v-model="newMessage.toRoles"
              :native-value="role"
              v-for="role in rolesArray"
              :key="`${role.id}-${role.gameslist.toString()}`"
            >
              <b-icon class="mr-1" size="is-small" :icon="role.icon"></b-icon>
              <span class="mr-4">{{
                role.name | capitalize({ onlyFirstLetter: true })
              }}</span>
            </b-checkbox>
          </div>
        </b-field>
        <b-field expanded label="Message :" class="mb-0">
          <b-input
            required
            type="textarea"
            rows="3"
            v-model.trim="newMessage.message"
          ></b-input>
        </b-field>
        <p class="mt-3 is-italic">
          Your message will be sent via the game chat to
          <b>{{ selectedRolesData.countRoles }}</b> stages, among which
          <b>{{ selectedRolesData.countPlayers }}</b> are being played by a
          participant.
        </p>
      </div>
      <div class="buttons is-centered mt-4">
        <b-button
          rounded
          size="is-medium"
          type="is-light"
          @click="$parent.close()"
          >Close</b-button
        >
        <b-button
          rounded
          size="is-medium"
          type="is-success"
          @click="sendAllMessages"
          :disabled="!newMessage.toRoles.length || !newMessage.message"
          >Send now</b-button
        >
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SendMessageModal",
  computed: {
    ...mapGetters([
      "error",
      "showError",
      "publicProfile",
      "authUser",
      "portalLook"
    ]),
    activeGames() {
      let tab = [...this.selectedGames];
      return tab.filter(game => {
        return !game.finished && !game.archived && !game.deleted;
      });
    },
    rolesArray() {
      let tab = [];
      this.activeGames.forEach(game => {
        Object.values(game.facilities).forEach(faci => {
          let lookForRole = tab.find(role => {
            return (
              role.id === faci.id &&
              role.name === faci.name &&
              role.icon === faci.icon
            );
          });
          if (!lookForRole) {
            tab.push({
              id: faci.id,
              icon: faci.icon,
              name: faci.name,
              gameslist: [game.id],
              nbconnected: faci.user ? 1 : 0
            });
          } else {
            lookForRole.gameslist.push(game.id);
            lookForRole.nbconnected += faci.user ? 1 : 0;
          }
        });
      });
      return tab.sort((a, b) => {
        return parseInt(a.id) - parseInt(b.id);
      });
    },
    selectedRolesData() {
      let obj = { countPlayers: 0, countRoles: 0 };
      this.newMessage.toRoles.forEach(role => {
        obj.countRoles += role.gameslist.length;
        obj.countPlayers += role.nbconnected;
      });
      return obj;
    },
    messagesList() {
      let gamesToTab = {};
      this.newMessage.toRoles.forEach(role => {
        role.gameslist.forEach(gid => {
          if (gamesToTab[gid]) {
            gamesToTab[gid].push(role.id);
            gamesToTab[gid] = [...new Set(gamesToTab[gid])];
          } else {
            gamesToTab[gid] = [role.id];
          }
        });
      });
      return Object.keys(gamesToTab).map(gid => {
        return {
          game_id: gid,
          to: gamesToTab[gid],
          from: "admin",
          message: this.newMessage.message
        };
      });
    }
  },
  props: {
    selectedGames: Array
  },
  methods: {
    sendAllMessages() {
      let updatePromises = [];
      this.$store.commit("setLoading", true);
      this.messagesList.forEach(mess => {
        updatePromises.push(this.$store.dispatch("sendMessage", mess));
      });
      return Promise.all(updatePromises).then(() => {
        this.$store.commit("setLoading", false);
      });
    }
  },
  mounted() {
    this.newMessage.toRoles = this.rolesArray;
  },
  data() {
    return {
      newMessage: {
        toRoles: [],
        message: ""
      }
    };
  }
};
</script>

<style lang="scss">
.sendmessage-modal-wrapper {
}
</style>
