<template>
  <div class="lean-settings-wrapper modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title has-text-centered">{{ game.name }} settings</p>
      <button
        class="delete"
        aria-label="close"
        @click="$parent.close()"
      ></button>
    </header>
    <section class="modal-card-body py-2">
      <b-notification
        :active="showError"
        @close="$store.commit('clearError')"
        type="is-danger"
        >{{ showError ? error.message : "" }}</b-notification
      >
      <div class="buttons my-0">
        <setup-buttons-list
          :selected-games="[game]"
          :is-creator-view="true"
          :can-unselect="false"
        ></setup-buttons-list>
      </div>
      <form v-if="newGameData" id="update-leangame-form" ref="settingsform">
        <div class="box" :key="`leansettingsname-${rankIncr}`">
          <b-field label="Game Name :" horizontal>
            <b-input
              v-model="newGameData.name"
              type="text"
              expanded
              required
              @keyup.enter.native="updateAllGame"
            ></b-input>
          </b-field>
        </div>

        <lean-config
          v-model="newGameData"
          :can-edit="true"
          ref="config"
          :key="`leansettings-${rankIncr}`"
          @updatestartingstock="startingStockUpdated = true"
        ></lean-config>
      </form>
    </section>
    <footer class="modal-card-foot buttons is-centered">
      <b-button
        rounded
        type="is-grey"
        size="is-medium"
        v-if="isChanged"
        @click="cancelChange"
        >Cancel</b-button
      >
      <b-button
        rounded
        form="update-leangame-form"
        type="is-success"
        tag="input"
        native-type="submit"
        size="is-medium"
        value="Update game"
        v-if="isChanged"
        @click.prevent="updateAllGame"
      ></b-button>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SetupButtonsList from "@/components/Dashboard/SetupButtonsList.vue";
import LeanConfig from "@/components/lean/Create/LeanConfig.vue";

export default {
  name: "LeanSettings",
  components: {
    SetupButtonsList,
    LeanConfig
  },
  computed: {
    ...mapGetters(["error", "showError"])
  },
  props: {
    game: Object
  },
  watch: {
    game: {
      handler() {
        this.newGameData = JSON.parse(JSON.stringify(this.game));
        this.isChanged = false;
      },
      deep: true
    },
    newGameData: {
      handler(newVal, oldVal) {
        if (newVal && oldVal) {
          if (JSON.stringify(newVal) !== JSON.stringify(this.game)) {
            this.isChanged = true;
          } else {
            this.isChanged = false;
          }
        }
      },
      deep: true
    }
  },
  created() {
    this.newGameData = JSON.parse(JSON.stringify(this.game));
  },
  methods: {
    cancelChange() {
      this.newGameData = JSON.parse(JSON.stringify(this.game));
      setTimeout(() => {
        this.rankIncr += 1;
        this.isChanged = false;
      }, 5);
    },
    updateAllGame() {
      let isValid = this.$refs.settingsform.checkValidity();
      if (isValid && this.isChanged) {
        this.$store
          .dispatch("updateGameDoc", [
            {
              gid: this.game.id,
              obj: this.newGameData
            }
          ])
          .then(() => {
            this.isChanged = false;
            if (this.startingStockUpdated && !this.game.params.game_start_at) {
              return this.$store.dispatch("updateLeanStartingStocks", {
                gid: this.game.id
              });
            }
            return true;
          })
          .then(() => {
            this.$buefy.toast.open("Game updated");
            this.startingStockUpdated = false;
          });
      } else if (this.isChanged) {
        this.$buefy.toast.open({
          message: "Please correct the errors in the values entered",
          type: "is-danger"
        });
      }
    }
  },
  data() {
    return {
      rankIncr: 0,
      isChanged: false,
      newGameData: null,
      startingStockUpdated: false
    };
  }
};
</script>

<style lang="scss">
.lean-settings-wrapper {
  .title.is-6 {
    margin-bottom: 0.5em;
  }
  .settings-bloc {
    margin-top: 0.5em;
    margin-bottom: 1em;
    padding: 1em 1.5em;
    .b-tabs.block {
      .tab-content {
        padding-top: 0;
        .lane-user-status {
          margin: 0 -1.25em;
        }
        .columns,
        .column {
          margin-bottom: 0;
        }
        .column {
          padding: left 3em;
          padding-right: 3em;
        }
      }
    }
  }
}
</style>
