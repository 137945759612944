<template>
  <div class="bg-settings-wrapper modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title has-text-centered">{{ game.name }} settings</p>
      <button
        class="delete"
        aria-label="close"
        @click="$parent.close()"
      ></button>
    </header>
    <section class="modal-card-body py-2">
      <b-notification
        :active="showError"
        @close="$store.commit('clearError')"
        type="is-danger"
        >{{ showError ? error.message : "" }}</b-notification
      >
      <div class="buttons my-0">
        <setup-buttons-list
          :selected-games="[game]"
          :is-creator-view="true"
          :can-unselect="false"
        ></setup-buttons-list>
        <b-button
          disabled
          size="is-small"
          class="ml-3 pr-0"
          v-if="game.params.playTimer && !game.finished && !game.archived"
        >
          <timer-clock
            tooltip="Time left for current turn"
            :active="game.params.playTimer"
            :timerNextTick="game.params.timerNextTick"
            :compact="false"
          ></timer-clock>
        </b-button>
      </div>
      <form v-if="newGameData" id="update-game-form" ref="settingsform">
        <b-collapse
          aria-id="generalId"
          class="box settings-bloc"
          animation="slide"
          :open="false"
        >
          <div
            class="title is-5"
            slot="trigger"
            slot-scope="props"
            aria-controls="generalId"
          >
            General settings
            <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
          </div>
          <div class="box-divider"></div>
          <div :key="`bgparams-${rankIncr}`">
            <b-field label="Game Name :" horizontal>
              <b-input
                v-model="newGameData.name"
                type="text"
                expanded
                required
                @keyup.enter.native="updateAllGame"
              ></b-input>
            </b-field>
            <div class="box-divider"></div>
            <bg-config-params
              :game="game"
              v-model="newGameData.params"
              @sendvalidate="updateAllGame"
            ></bg-config-params>
          </div>
        </b-collapse>
        <b-collapse
          aria-id="custoDemandId"
          class="box settings-bloc"
          animation="slide"
          :open="false"
          @open="updateDemandChart"
        >
          <div
            class="title is-5"
            slot="trigger"
            slot-scope="props"
            aria-controls="custoDemandId"
          >
            Final Consumer Demand
            <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
          </div>
          <div class="box-divider"></div>
          <bg-config-demand
            :key="`bgdemand-${rankIncr}`"
            @sendvalidate="updateAllGame"
            ref="myConfigDemand"
            v-model="newGameData.params.consumerDemand"
            :total-weeks="newGameData.params.totalWeeks"
            :my-style="{ 'max-height': '250px' }"
            :disable="false"
          ></bg-config-demand>
        </b-collapse>
        <b-collapse
          aria-id="messagesId"
          class="box settings-bloc"
          animation="slide"
          :open="false"
        >
          <div
            class="title is-5"
            slot="trigger"
            slot-scope="props"
            aria-controls="messagesId"
          >
            Messages
            <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
          </div>
          <div class="box-divider"></div>
          <bg-config-messages
            :key="`bgmessages-${rankIncr}`"
            v-model="newGameData.params.configMessages"
            :facilities="newGameData.facilities"
            :game="game"
            ref="myConfigMessages"
            :disable="false"
            :time-unit="newGameData.params.timeUnit"
          ></bg-config-messages>
        </b-collapse>
        <b-collapse
          aria-id="allFaciId"
          class="box settings-bloc"
          animation="slide"
          :open="false"
        >
          <div
            class="title is-5"
            slot="trigger"
            slot-scope="props"
            aria-controls="allFaciId"
          >
            Supply-Chain Stages
            <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
          </div>
          <div class="box-divider mb-4"></div>
          <bg-config-faci
            :game="game"
            :key="`bgfaci-${rankIncr}`"
            v-model="newGameData.facilities"
            :isLive="true"
            ref="myConfigFaci"
            @sendvalidate="updateAllGame"
            @sawleadtimeupdate="leadTimeUpdated = true"
          ></bg-config-faci>
        </b-collapse>
      </form>
    </section>
    <footer class="modal-card-foot buttons is-centered">
      <b-button
        rounded
        type="is-grey"
        size="is-medium"
        v-if="isChanged"
        @click="cancelChange"
        >Cancel</b-button
      >
      <b-button
        rounded
        form="update-game-form"
        type="is-success"
        tag="input"
        native-type="submit"
        size="is-medium"
        value="Update game"
        v-if="isChanged"
        @click.prevent="updateAllGame"
      ></b-button>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BgConfigDemand from "@/components/bg/Create/BgConfigDemand.vue";
import BgConfigMessages from "@/components/bg/Create/BgConfigMessages.vue";
import BgConfigFaci from "@/components/bg/Create/BgConfigFaci.vue";
import BgConfigParams from "@/components/bg/Create/BgConfigParams.vue";
import SetupButtonsList from "@/components/Dashboard/SetupButtonsList.vue";
import TimerClock from "@/components/Dashboard/TimerClock.vue";

export default {
  name: "BgSettings",
  components: {
    BgConfigDemand,
    BgConfigParams,
    BgConfigFaci,
    SetupButtonsList,
    BgConfigMessages,
    TimerClock
  },
  computed: {
    ...mapGetters(["error", "showError"])
  },
  props: {
    game: Object
  },
  watch: {
    game: {
      handler() {
        this.newGameData = JSON.parse(JSON.stringify(this.game));
        this.isChanged = false;
      },
      deep: true
    },
    newGameData: {
      handler(newVal, oldVal) {
        if (newVal && oldVal) {
          if (JSON.stringify(newVal) !== JSON.stringify(this.game)) {
            this.isChanged = true;
          } else {
            this.isChanged = false;
          }
        }
      },
      deep: true
    }
  },
  created() {
    this.newGameData = JSON.parse(JSON.stringify(this.game));
  },
  methods: {
    updateDemandChart() {
      setTimeout(() => {
        if (
          this.$refs.myConfigDemand &&
          this.$refs.myConfigDemand.$refs.myDemandChart
        ) {
          this.$refs.myConfigDemand.$refs.myDemandChart.$refs.chart.launchUpdate();
        }
      }, 10);
    },
    cancelChange() {
      this.newGameData = JSON.parse(JSON.stringify(this.game));
      this.rankIncr += 1;
      this.isChanged = false;
      this.leadTimeUpdated = false;
    },
    updateAllGame() {
      let isValid = this.$refs.settingsform.checkValidity();
      if (isValid && this.isChanged) {
        this.$store
          .dispatch("updateGameDoc", [
            {
              gid: this.game.id,
              obj: this.newGameData,
              type: this.game.type,
              game: this.game,
              timer: this.game.params.timer
            }
          ])
          .then(() => {
            this.isChanged = false;
            if (this.leadTimeUpdated && this.game.week === 0) {
              return this.$store.dispatch("updateGameStartingOrders", {
                gid: this.game.id
              });
            }
            return true;
          })
          .then(() => {
            this.$buefy.toast.open("Game updated");
            this.leadTimeUpdated = false;
          });
      } else if (this.isChanged) {
        this.$buefy.toast.open({
          message: "Please correct the errors in the values entered",
          type: "is-danger"
        });
      }
    }
  },
  data() {
    return {
      rankIncr: 0,
      leadTimeUpdated: false,
      isChanged: false,
      newGameData: null
    };
  }
};
</script>

<style lang="scss">
.bg-settings-wrapper {
  .title.is-6 {
    margin-bottom: 0.5em;
  }
  .settings-bloc {
    margin-top: 0.5em;
    margin-bottom: 1em;
    padding: 1em 1.5em;
    .b-tabs.block {
      .tab-content {
        padding-top: 0;
        .lane-user-status {
          margin: 0 -1.25em;
        }
        .columns,
        .column {
          margin-bottom: 0;
        }
        .column {
          padding: left 3em;
          padding-right: 3em;
        }
      }
    }
  }
}
</style>
