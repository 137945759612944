<template>
  <div class="setup-buttons-list buttons my-0">
    <b-button
      type="is-warning"
      size="is-small"
      icon-left="content-copy"
      @click="dispatchFunction('copyGames')"
      >Duplicate {{ isCreatorView ? "" : "on my space" }}</b-button
    >
    <b-button
      type="is-black"
      size="is-small"
      icon-left="account-multiple-remove"
      @click="dispatchFunction('disconnectPlayers')"
      v-if="availBtns['discoPlayers']"
      >Disconnect players</b-button
    >
    <b-button
      type="is-info is-light"
      size="is-small"
      icon-left="email"
      @click="showSendMessageModal = true"
      v-if="availBtns['message']"
      >Send Message</b-button
    >
    <span class="ml-2"></span>
    <!-- BG Buttons : -->
    <b-button
      size="is-small"
      type="is-danger"
      icon-left="close-circle"
      @click="
        applyStatus([
          { key: 'finished', val: true },
          { key: 'params.playTimer', val: false }
        ])
      "
      v-if="availBtns['finishBg']"
      >Set Finished</b-button
    >
    <b-button
      size="is-small"
      type="is-success"
      icon-left="power"
      @click="applyStatus([{ key: 'finished', val: false }])"
      v-if="availBtns['reactivateBg']"
      >Set Active</b-button
    >
    <span class="ml-2"></span>
    <b-button
      size="is-small"
      type="is-info"
      icon-left="timer-outline"
      @click="dispatchFunction('startBgTimer')"
      v-if="availBtns['playBgTimer']"
      >Start Timer</b-button
    >
    <b-button
      size="is-small"
      type="is-info"
      icon-left="timer-off-outline"
      @click="applyStatus([{ key: 'params.playTimer', val: false }])"
      v-if="availBtns['stopBgTimer']"
      >Stop Timer</b-button
    >
    <!-- LEAN Buttons -->
    <b-button
      size="is-small"
      type="is-success"
      icon-left="play"
      @click="dispatchFunction('startLeanRun')"
      v-if="availBtns['startRunLean']"
      >Start Run</b-button
    >
    <b-button
      size="is-small"
      type="is-danger"
      icon-left="stop"
      @click="
        applyStatus([
          { key: 'finished', val: true },
          { key: `params.game_end_at`, val: new Date() }
        ])
      "
      v-if="availBtns['endRunLean']"
      >End Run</b-button
    >

    <b-button
      size="is-small"
      type="is-danger is-light"
      icon-left="reload"
      @click="dispatchFunction('resetLeanRun')"
      v-if="availBtns['resetRunLean']"
      >Reset Run</b-button
    >
    <span class="ml-2"></span>
    <b-button
      size="is-small"
      type="is-warning"
      icon-left="package-down"
      @click="applyStatus([{ key: 'archived', val: true }])"
      v-if="availBtns['archive']"
      >Archive</b-button
    >
    <b-button
      size="is-small"
      type="is-success"
      icon-left="inbox-arrow-down"
      @click="applyStatus([{ key: 'archived', val: false }])"
      v-if="availBtns['unarchive']"
      >Unarchive</b-button
    >
    <b-button
      size="is-small"
      type="is-black"
      icon-left="cancel"
      @click="dispatchFunction('deleteGames')"
      v-if="availBtns['canDelete']"
      >Delete</b-button
    >
    <em class="ml-1 pb-2" v-if="canUnselect"
      >{{ selectedGames.length }} selected</em
    >
    <b-button
      class="ml-3"
      size="is-small"
      v-if="canUnselect"
      rounded
      icon-left="close"
      @click="$emit('unselect')"
      >Clear</b-button
    >
    <b-modal :active.sync="showSendMessageModal" has-modal-card>
      <send-message-modal :selected-games="selectedGames"></send-message-modal>
    </b-modal>
  </div>
</template>

<script>
import SendMessageModal from "@/components/Dashboard/SendMessageModal.vue";

export default {
  name: "SetupButtonsList",
  components: {
    SendMessageModal
  },
  props: {
    selectedGames: Array,
    isCreatorView: Boolean,
    canUnselect: Boolean
  },
  computed: {
    singleType() {
      // Check if only one game type selected
      let retType = null;
      let isSingle = true;
      this.selectedGames.forEach(game => {
        if (!retType) {
          retType = game.type;
        } else if (retType != game.type) {
          isSingle = false;
        }
      });
      return isSingle ? retType : null;
    },
    availBtns() {
      let discoPlayers = false;
      let archive = false;
      let unarchive = false;
      let canDelete = false;
      let message = false;
      //BG buttons :
      let finishBg = false;
      let reactivateBg = false;
      let playBgTimer = false;
      let stopBgTimer = false;
      //LEAN buttons :
      let startRunLean = false;
      let endRunLean = false;
      let resetRunLean = false;

      // For the creator and other team members:
      this.selectedGames.forEach(game => {
        if (
          Object.values(game.facilities).some(fac => {
            return fac.user;
          })
        ) {
          discoPlayers = true;
        }
      });

      // ONLY for the creator of the game:
      if (this.isCreatorView) {
        canDelete = true;
        this.selectedGames.forEach(game => {
          if (game.archived) {
            unarchive = true;
          } else {
            archive = true;
            if (!game.finished) {
              message = true;
            }
            // The below buttons depend on the type of games selected.
            if (this.singleType === "beergame") {
              if (game.finished) {
                reactivateBg = true;
              } else {
                finishBg = true;
                if (!game.params.playTimer) {
                  playBgTimer = true;
                } else {
                  stopBgTimer = true;
                }
              }
            }
            if (this.singleType === "leangame") {
              if (!game.params.game_start_at) {
                startRunLean = true;
              } else {
                if (!game.params.game_end_at) {
                  endRunLean = true;
                } else {
                  resetRunLean = true;
                }
              }
            }
          }
        });
      }
      return {
        discoPlayers,
        archive,
        unarchive,
        finishBg,
        message,
        reactivateBg,
        playBgTimer,
        stopBgTimer,
        canDelete,
        startRunLean,
        endRunLean,
        resetRunLean
      };
    }
  },
  methods: {
    applyStatus(myChanges) {
      let arrChange = [];
      this.selectedGames.forEach(gameData => {
        let newObj = {
          changed: false,
          gid: gameData.id,
          type: gameData.type,
          timer: gameData.params.timer,
          obj: {},
          game: gameData
        };
        myChanges.forEach(change => {
          if (gameData[change.key] !== change.val) {
            newObj.obj[change.key] = change.val;
            newObj.changed = true;
          }
        });
        if (newObj.changed) {
          arrChange.push(newObj);
        }
      });
      this.$store.dispatch("updateGameDoc", arrChange).then(() => {
        if (this.canUnselect) {
          this.$emit("unselect");
        }
      });
    },
    dispatchFunction(funcName) {
      this.$store.dispatch(funcName, this.selectedGames).then(() => {
        if (this.canUnselect) {
          this.$emit("unselect");
        }
      });
    }
  },
  data() {
    return { showSendMessageModal: false };
  }
};
</script>

<style lang="scss"></style>
