<template>
  <div
    class="timer-clock has-text-centered m-0"
    v-if="active"
    :class="{
      'has-text-danger': remaining <= 10000 && remaining > 0 && !success,
      'has-text-success': success
    }"
  >
    <b-tooltip :label="tooltip" type="is-dark" position="is-bottom">
      <span class="nowrap">
        <b-icon
          icon="timer"
          class="pr-2"
          v-if="!compact"
          size="is-small"
        ></b-icon>
        <span class="is-invisible ml-4">00::00</span>
        <span class="is-abs ml-4" v-if="!success">
          {{ timerData.remainingText }}
        </span>
        <span class="is-abs pl-3" v-if="success">
          <b-icon icon="check"></b-icon>
        </span>
      </span>
    </b-tooltip>
  </div>
</template>

<script>
export default {
  name: "TimerClock",
  props: {
    timerNextTick: Object,
    active: Boolean,
    success: Boolean,
    tooltip: String,
    showAlerts: Boolean,
    compact: Boolean
  },
  computed: {
    timerData() {
      let minutes = Math.max(0, Math.floor(this.remaining / (60 * 1000)));
      let seconds = Math.max(
        0,
        Math.floor((this.remaining - minutes * 60000) / 1000)
      );
      return {
        remainingText:
          (minutes < 10 ? "0" + minutes : minutes) +
          ":" +
          (seconds < 10 ? "0" + seconds : seconds)
      };
    }
  },
  mounted() {
    if (this.active) {
      this.updateTimer();
    }
  },
  methods: {
    updateTimer() {
      this.remaining = 0;
      if (this.timerNextTick && this.timerNextTick.seconds) {
        this.remaining = this.timerNextTick.seconds * 1000 - Date.now();
      }
      if (!this.active) {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
        return;
      }
      this.timeout = setTimeout(() => {
        this.updateTimer();
      }, 1000);
    }
  },
  watch: {
    active(newVal) {
      if (newVal) {
        this.updateTimer();
      }
    },
    remaining(newRem, oldRem) {
      if (this.showAlerts && newRem && oldRem && newRem < oldRem) {
        this.showAlertTimers.forEach(alert => {
          if (Math.round(newRem / 1000) === alert.sec) {
            this.$buefy.toast.open({
              duration: 2000,
              message: `⌛ In ${alert.sec} seconds an automatic order will be sent for you ⌛`
            });
          }
        });
      }
    }
  },
  data() {
    return {
      showAlertTimers: [{ sec: 20 }, { sec: 10 }, { sec: 5 }],
      remaining: 0,
      timeout: null
    };
  }
};
</script>

<style lang="scss">
.timer-clock {
  .is-abs {
    position: absolute;
    left: 0;
  }
}
</style>
